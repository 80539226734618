import { MenubarService } from "./menubar.service";
import { DataService } from "./../../services/data.service";
import {
  Router,
  ActivatedRoute,
  ParamMap,
  NavigationStart
} from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { NavigationEnd  } from '@angular/router';
import { FacebookTrackingService } from "../../app/facebook-tracking.service";

@Component({
  selector: "app-menubar",
  templateUrl: "./menubar.component.html",
  styleUrls: ["./menubar.component.scss"]
})
export class MenubarComponent implements OnInit {
  sidebarExpanded: boolean = false;
  city: string = "";
  // currentRoute: string = "malith";
  // ab: boolean = false;
  // menu = [
  //   { label: "Startseite", link: `/${this.data.city}` },
  //   { label: "Weinkeller", link: `raum/${this.data.city}/weinkeller` },
  //   { label: "Partyraum", link: `raum/${this.data.city}/partyraum` },
  //   { label: "Anfrage", link: `anfrage/${this.data.city}` }
  // ];

  constructor(
    private router: Router,
    public menubarService: MenubarService,
    public data: DataService,
    private gtmService: GoogleTagManagerService,
    private fb: FacebookTrackingService
  ) {

  }

  ngOnInit() {

  }

  toggleSidebar() {
    this.sidebarExpanded = !this.sidebarExpanded;
  }

  redirect(link: string) {
    this.toggleSidebar();
    this.router.navigateByUrl(link);
  }

  sendGtagEvent(event: string, room: string, city: string) {
    this.gtmService.pushTag({
      event,
      data: { room, city }
    });

    this.fb.trackViewContent(room, city);
  }

  sendFBLead(): void {
    this.fb.trackLead('WhatsApp');
  }

  public async anfrage() {
    await this.router.navigateByUrl('/anfrage/linz');
  }
}
