import { DataService } from "./../../services/data.service";
import { MetaService } from "./../../services/meta.service";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { MenubarService } from "./../../components/menubar/menubar.service";

@Component({
  selector: "app-formpage",
  templateUrl: "./formpage.component.html",
  styleUrls: ["./formpage.component.scss"]
})
export class FormpageComponent implements OnInit {
  // city: string = "linz";

  constructor(
    private activatedRoute: ActivatedRoute,
    private meta: MetaService,
    private menubar: MenubarService,
    public data: DataService
  ) {
    this.activatedRoute.params.subscribe((params: any) => {
      this.data.city = 'linz';
    });
    this.meta.setMeta("Anfrage");
  }

  ngOnInit() {
    this.menubar.cancel();
  }
}
