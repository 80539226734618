import { DataService } from "../../services/data.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-dankepage",
  templateUrl: "./danke.component.html",
  styleUrls: ["./danke.component.scss"],
})
export class DankeComponent implements OnInit {
  constructor(public data: DataService) {}

  ngOnInit() {}
}
