import { Component, HostListener, Inject } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { WINDOW } from "@ng-toolkit/universal";
import { GoogleTagManagerService } from "angular-google-tag-manager";

declare var $: any;
declare var gtag: any;

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent {
	constructor(
		public router: Router,
		private gtmService: GoogleTagManagerService
	) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				gtag("config", "UA-139260511-2", {
					page_path: event.urlAfterRedirects,
				});
				this.gtmService.pushTag({
					event: "page",
					pageName: event.urlAfterRedirects,
				});
			}
		});
	}

	ngOnInit() {
		let cc = window as any;
		cc.cookieconsent.initialise({
			palette: {
				popup: {
					background: "#111",
					text: "#fff",
				},
				button: {
					background: "#fff",
					text: "#111",
				},
			},
			theme: "block",
			content: {
				message:
					"Wir nehmen Ihren Datenschutz sehr ernst und behandeln Ihre personenbezogenen Daten vertraulich und entsprechendder gesetzlichen Vorschriften. Wenn Sie fortfahren und unsere Webseite weiterhin nutzen, akzeptieren Sie die Verwendung unserer notwendigen Cookies und die Nutzungsbedingungen.",
				dismiss: "OK",
			},
		});
	}
}
