import { MetaService } from "./../../services/meta.service";
import { ActivatedRoute } from "@angular/router";
import { DataService } from "./../../services/data.service";
import { ParallaxService } from "./../../services/parallax.service";
import { Component, OnInit } from "@angular/core";
import { MenubarService } from "./../../components/menubar/menubar.service";
import { FacebookTrackingService } from "../../app/facebook-tracking.service";

@Component({
	selector: "app-Start",
	templateUrl: "./Start.component.html",
	styleUrls: ["./Start.component.css"],
})
export class StartComponent implements OnInit {
	isPlaying: boolean = false;

	constructor(
		public parallax: ParallaxService,
		public data: DataService,
		private ar: ActivatedRoute,
		private meta: MetaService,
		private menubar: MenubarService,
		private fb: FacebookTrackingService
	) {
		this.getCity();
		this.meta.setMeta("");
	}

	Object = Object;

	ngOnInit() {
		this.menubar.cancel();
	}

	playVideo() {
		if (!this.isPlaying) {
			let video = document.getElementById("video") as HTMLVideoElement;
			video.play();
			this.isPlaying = true;
		}
	}

	getCity() {
		this.ar.params.subscribe((params: any) => {
			if (params.city !== undefined) {
				this.data.city = 'linz';
			}
		});
	}

	getPersonen(room) {
		let value = room.value.list.find( l => l.toLowerCase().indexOf("person") !== -1);

		if (value) {
			value = value[0].toLowerCase() + value.substr(1);
		}

		return value;
	}

	public trackViewContent(room: string) {
		this.fb.trackViewContent(room, "room");
	}
}
