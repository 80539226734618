import { LightboxService } from "./lightbox.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-lightbox",
  templateUrl: "./lightbox.component.html",
  styleUrls: ["./lightbox.component.css"]
})
export class LightboxComponent implements OnInit {
  constructor(public lightbox: LightboxService) {}

  ngOnInit() {}
}
