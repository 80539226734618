import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { StartComponent } from "./../pages/Start/Start.component";
import { RoomDetailsComponent } from "./../pages/roomDetails/roomDetails.component";
import { FormpageComponent } from "./../pages/formpage/formpage.component";
import { ImprintPageComponent } from "../pages/imprint/imprintpage.component";
import {DankeComponent} from '../pages/danke/danke.component';

const routes: Routes = [
  { path: "", component: StartComponent },
  { path: "stadt/linz", component: StartComponent },
  { path: "raum/linz/:room", component: RoomDetailsComponent },
  { path: "anfrage/linz", component: FormpageComponent },
  { path: "imprint", component: ImprintPageComponent },
  { path: "danke", component: DankeComponent, pathMatch: "full" },
  { path: "html", redirectTo: "", pathMatch: "full" },
  { path: "html/mieten", redirectTo: "", pathMatch: "full" },
  { path: "html/anfrage", redirectTo: "", pathMatch: "full" },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
