import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: [
      '../../tailwind.scss',
      './testimonials.component.scss'
  ]
})
export class TestimonialsComponent implements OnInit {

    public testimonials = [
        {
            name: 'Verena Kunze',
            text: 'Unkomplizierte Buchung und Raumübergabe. Idealer Partyraum für die Jugend fernab von Wohngebäuden.\n' +
                'Coole Licht-und Musikanlage. Jederzeit wieder!',
            source: 'google',
            rating: 5,
            image: '2'
        },
        {
            name: 'Nina Zehetner',
            text: 'Der Raum ist schön aufgeteilt und mit tollen Details dekoriert. Es ist genügend Platz für alle Leute und auch dass mehrere Tische zur Verfügung stehen ist toll.\n' +
                'Dadurch dass auch eine Heizung vorhanden ist, ist es drinnen im Winter nicht ganz so kalt.\n' +
                'Das Team steht einem jederzeit zur Verfügung um offene Fragen zu klären.',
            source: 'google',
            rating: 5,
            image: '10'
        },
        {
            name: 'Katharina Krainer',
            text: 'super Organisation, alle waren sehr freundlich :) Außerdem ist der Raum groß, gut mit den Öffis zu erreichen und es wird Wert auf Sauberkeit gelegt\n' +
                'insgesamt war es ein toller Abend, danke!',
            source: 'google',
            rating: 5,
            image: '4'
        },
        {
            name: 'Sebastian Kögler',
            text: 'Tolle Location. Für eine private Feier bis zu 50 Personen einfach Top. Und besonderen Dank an Monika :-) (hat mit uns alles professionell abgewickelt). Einziges kleines Manko ist das Ablüftungssystem - vielleicht könnte man dieses ev. mal verbessern. Ansonsten nur zu empfehlen!! Danke 🤗',
            source: 'google',
            rating: 4,
            image: '5'
        },
        {
            name: 'Patrick Rohrmoser',
            text: 'Ich habe meinen 30er im Weinkeller gefeiert und kann es jedem nur empfehlen. Location ist Top, das einzige was man braucht sind Essen und Getränke und seine Leute.\n' +
                '\n' +
                'Unkompliziert zu erreichen, sei es von der Autobahn oder per Zug.\n' +
                '\n' +
                'Abwicklung mit dem Inhaber und seinem Team lief reibungslos. Super freundlich und jederzeit erreichbar.',
            source: 'google',
            rating: 5,
            image: '3'
        },
        {
            name: 'Eva Buder',
            text: 'Es war eine perfekte 40er Party, jederzeit wieder! Es wurde gefeiert, gegessen und viel getanzt! Das Ambiente war schön für unsere Zwecke sehr gut geeignet! Die Location ist zentral und für alle gut erreichbar! Die Abwicklung und Organisation hat auch einwandfrei geklappt!',
            source: 'google',
            rating: 5,
            image: '6'

        },
        {
            name: 'Franziska',
            text: 'Sehr schöner Raum und problemlose Abwicklung! Vor allem die Musikanlage ist bei den Gästen sehr gut angekommen. Jederzeit wieder!',
            source: 'google',
            rating: 5,
            image: '10'
        },
        {
            name: 'Desirree Schmidt',
            text: 'Perfekt für 60 Personen, Kühlschränke, Musikanlage,...alles vorhanden. Einfache Buchung.',
            source: 'google',
            rating: 5,
            image: '12'
        }
    ];

  constructor() { }

  ngOnInit() {
  }

}
