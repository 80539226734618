import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class LightboxService {
  url: string = "";

  constructor() {}

  showForURL(url: string) {
    this.url = url;
  }

  reset() {
    this.url = "";
  }

  get visible() {
    return this.url.length > 0;
  }
}
