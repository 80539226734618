import { DataService } from "./../../services/data.service";
import {ActivatedRoute, Router} from '@angular/router';
import { Component, OnInit, Input, Inject } from "@angular/core";
import { WINDOW } from "@ng-toolkit/universal";
import { HttpClient } from "@angular/common/http";
import { DateAdapter } from "@angular/material/core";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { FacebookTrackingService } from "../../app/facebook-tracking.service";

declare var $: any;

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css"]
})
export class FormComponent implements OnInit {
  isShow = true;
  @Input() room: string;
  @Input() hasRoom: boolean = false;
  @Input() city: string;

  selectedDate: Date = new Date();

  tempAltDates: any[] = [];
  formData: any = {
    // name: '',
    email: "",
    phone: "",
    wishDate: "",
    alternativeDates: [],
    room: "",
    birthdate: ""
  };

  constructor(
    @Inject(WINDOW) private window: Window,
    private route: ActivatedRoute,
    private http: HttpClient,
    public data: DataService,
    private router: Router,
    private _adapter: DateAdapter<any>,
    private gtmService: GoogleTagManagerService,
    private fb: FacebookTrackingService
  ) {
    this._adapter.setLocale("de");
    //Set Room for Form
    this.route.params.subscribe(params => {
      this.window.setTimeout(() => {
        if (params.room !== undefined) {
          this.formData.room = params.room;
        }
      }, 1000);
    });
  }

  ngOnInit() {}

  // focusBirthdateInput() {
  //   $("#birthdate-toggle").focus();
  // }

  // focusDesiredateInput() {
  //   $("#desiredate-toggle").focus();
  // }
  // focusAltdateInput() {
  //   $("#altdate-toggle").focus();
  // }



  toggleDisplay() {
      this.isShow = !this.isShow;
  }

  errors: any = {};
  checkForm() {
    let passed = true;
    this.errors = {};

    if (/\S+@\S+\.\S+/.test(this.formData.email) === false) {
      passed = false;
      this.errors.email = "Bitte geben Sie eine gültige Emailadresse ein";
    }
    // if (
    //   /[0-9]{2}(\.)[0-9]{2}(\.)[0-9]{4}/.test(this.selectedDate) === false
    // ) {
    //   passed = false;
    //   this.errors.wishDate = "Bitte geben Sie ein Wunschdatum an (dd.mm.yyyy)";
    // }

    if (this.formData.birthdate.includes("-")) {
      let b = this.formData.birthdate.split("-");
      this.formData.birthdate = `${b[2]}.${b[1]}.${b[0]}`;
    }
    if (
      /[0-9]{2}(\.)[0-9]{2}(\.)[0-9]{4}/.test(this.formData.birthdate) === false
    ) {
     // passed = false;
     // this.errors.birthdate =
     //   "Bitte geben Sie ein gültiges Geburtsdatum an (dd.mm.yyyy)";
    }
    if (this.formData.phone.length === 0) {
      passed = false;
      this.errors.phone = "Bitte geben Sie eine gültige Telefonnummer an";
    }
    /* if (this.formData.room.length === 0) {
      passed = false;
      this.errors.room = "Bitte wählen Sie einen Raum";
    }
    console.log("len : " + this.formData.alternativeDates.length); */

    for (let index = 0; index < this.formData.alternativeDates.length; index++) {
      console.log("Alter Date : " + this.formData.alternativeDates[index]);
      console.log(/[0-9]{2}(\.)[0-9]{2}(\.)[0-9]{4}/.test(this.formData.alternativeDates[index]) === false);

      // if (
      //   /[0-9]{2}(\.)[0-9]{2}(\.)[0-9]{4}/.test(this.formData.alternativeDates[index]) === false
      // ) {
      //   console.log("Error massage");
      //   passed = false;
      //   console.log("Error massage" + passed);
      //   this.errors.alternativeDates = "Bitte geben Sie ein Wunschdatum an (dd.mm.yyyy)";
      //   console.log("Error massage : " + this.errors.alternativeDates);
      // }

    }

    return passed;
  }

  isSending: boolean = false;
  sendEmail() {
    let d = this.formData;

    if (this.checkForm()) {
      this.isSending = true;

      let datesString = this.selectedDate.toLocaleDateString() + ";";
      d.alternativeDates.forEach((date: string) => {
        datesString += date + ";";
      });
      // console.log(this.formData);
      // console.log(datesString);

      const url2 =
          `https://us-central1-eventwerkstadt-bb403.cloudfunctions.net/contact?` +
          `city=${encodeURI(this.city !== undefined ? this.city : this.data.city)}&` +
          `room=${encodeURI(d.room)}&` +
          `email=${encodeURI(d.email)}&` +
          `phone=${encodeURI(d.phone.replace(/ /g, ""))}&` +
          `birthdate=${encodeURI(d.birthdate)}&` +
          `datesString=${encodeURI(datesString)}`;

      this.http
        .get(url2)
        .toPromise()
        .then((res: any) => {
          this.isSending = false;

          this.router.navigateByUrl("/danke");

          this.sendGtagEvent(
            "anfrage-form",
            d.room,
            this.city !== undefined ? this.city : this.data.city
          );

          this.fb.trackLead("form");

        })
        .catch((res: any) => {
          this.isSending = false;
          if (res.statusText !== "OK") {
            alert(
              "Leider ist etwas ist schiefgelaufen. Bitte versuchen Sie es in wenigen Minuten erneut erneut."
            );
          } else {
            this.router.navigateByUrl("/danke");
          }
        });
    }
  }

  range(start: number, end: number) {
    return Array(end - start + 1)
      .fill(1)
      .map((_, idx) => start + idx);
  }

  get currentYear(): number {
    return new Date().getFullYear();
  }

  addAlternativeDate() {
    this.tempAltDates.push("");
  }

  deleteAlternativeDate(index: number) {
    this.formData.alternativeDates.splice(index, 1);
    this.tempAltDates.splice(index, 1);
  }

  sendGtagEvent(event: string, room: string, city: string) {
    this.gtmService.pushTag({
      event,
      data: { room, city }
    });
  }
}
