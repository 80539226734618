import { ActivatedRoute } from "@angular/router";
import { Injectable } from "@angular/core";
import * as data from "./../assets/rooms.json";

@Injectable({
  providedIn: "root"
})
export class DataService {
  rooms: any = (data as any).default;
  city: string = "linz";

  constructor() {}
}
