import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class MenubarService {
  showMarkMess: boolean = false;
  randomPeopleNumber: number = 1;

  constructor() {}

  timeout: any;
  showMessage(timeoutMS?: number) {
    this.timeout = setTimeout(
      () => {
        this.randomPeopleNumber = Math.floor(Math.random() * 5) + 2;
        this.showMarkMess = true;
      },
      timeoutMS !== undefined ? timeoutMS : 5000
    );
  }

  cancel() {
    clearTimeout(this.timeout);
    this.close();
  }

  close() {
    this.showMarkMess = false;
  }
}
