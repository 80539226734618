import { MenubarService } from "./../components/menubar/menubar.service";
import { MetaService } from "./../services/meta.service";
import { DataService } from "./../services/data.service";
import { LightboxService } from "./../components/lightbox/lightbox.service";
import { LightboxComponent } from "./../components/lightbox/lightbox.component";
import { FormpageComponent } from "./../pages/formpage/formpage.component";
import { FormComponent } from "./../components/form/form.component";

import { MenubarComponent } from "./../components/menubar/menubar.component";
import { NgtUniversalModule } from "@ng-toolkit/universal";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { TransferHttpCacheModule } from "@nguniversal/common";
import { NgModule } from "@angular/core";
import { AgmCoreModule } from "@agm/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { StartComponent } from "./../pages/Start/Start.component";
import { ParallaxService } from "./../services/parallax.service";
import { FooterComponent } from "./../components/footer/footer.component";
import { RoomDetailsComponent } from "./../pages/roomDetails/roomDetails.component";
import { ImprintPageComponent } from "./../pages/imprint/imprintpage.component";
import { FormsModule } from "@angular/forms";

import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatNativeDateModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import {DankeComponent} from '../pages/danke/danke.component';
import { TestimonialsComponent } from '../components/testimonials/testimonials.component';
import { NgxMasonryModule } from "ngx-masonry";

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    MenubarComponent,
    ImprintPageComponent,
    FooterComponent,
    RoomDetailsComponent,
    FormComponent,
    FormpageComponent,
    LightboxComponent,
      DankeComponent,
      TestimonialsComponent
  ],
  imports: [
    CommonModule,
    NgtUniversalModule,
    TransferHttpCacheModule,
    HttpClientModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDvWMQS41puaT2B2_ZTkQzF_SPovCb96GE",
    }),
    FormsModule,
      NgxMasonryModule
  ],
  providers: [
    ParallaxService,
    LightboxService,
    DataService,
    MetaService,
    MenubarService,
    { provide: "googleTagManagerId", useValue: "GTM-TT7CBM5" },
  ],
})
export class AppModule {}
