import { MenubarService } from "./../../components/menubar/menubar.service";
import { MetaService } from "./../../services/meta.service";
import { DataService } from "./../../services/data.service";
import { LightboxService } from "./../../components/lightbox/lightbox.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ParallaxService } from "../../services/parallax.service";
import { FacebookTrackingService } from "../../app/facebook-tracking.service";

declare var $: any;

@Component({
	selector: "app-roomDetails",
	templateUrl: "./roomDetails.component.html",
	styleUrls: ["./roomDetails.component.scss"],
})
export class RoomDetailsComponent implements OnInit {
	currentRoom: string;
	galleryIndex: number = 0;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		public parallax: ParallaxService,
		private lightbox: LightboxService,
		public data: DataService,
		private meta: MetaService,
		private menubar: MenubarService,
		private fb: FacebookTrackingService
	) {
		this.activatedRoute.params.subscribe((params) => {
			if (params["room"] === undefined) {
				this.router.navigateByUrl("/");
			}
			this.currentRoom = params.room;
			this.data.city = 'linz';
			this.meta.setMeta(this.currentRoom);

			if (this.menubar.showMarkMess) {
				this.menubar.cancel();
			} else {
				this.menubar.showMessage();
			}
		});
	}

	ngOnInit() {}

	nextImage() {


		if (
			this.galleryIndex + 1 >
			this.data.rooms[this.currentRoom].images.length - 1
		) {
			this.galleryIndex = 0;
		} else {
			this.galleryIndex++;
		}
	}

	previousImage() {
		if (this.galleryIndex - 1 === -1) {
			this.galleryIndex = 0;
		} else {
			this.galleryIndex--;
		}
	}

	openImage(url: string) {
		this.lightbox.showForURL(url);
	}

	scrollToForm() {
		$("html, body").animate({ scrollTop: $("#form").offset().top - 100 }, 1000);
		this.fb.trackViewContent(this.currentRoom, this.data.city);
	}

}
