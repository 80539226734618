import { Injectable } from '@angular/core';

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class FacebookTrackingService {

  constructor() { }

  trackViewContent(contentId: string, contentType: string): void {
    if (window.fbq) {
      window.fbq('track', 'ViewContent', {
        content_ids: [contentId],
        content_type: contentType
      });

      console.log('Facebook Tracking: ViewContent', contentId, contentType);
    } else {
      console.warn('Facebook Tracking is not initialized');
    }
  }

  trackLead(contentName: string): void {
    if (window.fbq) {
      window.fbq('track', 'Lead', {
        content_name: contentName
      });

      console.log('Facebook Tracking: Lead', contentName);
    } else {
      console.warn('Facebook Tracking is not initialized');
    }
  }

}
