import { Injectable, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { WINDOW } from '@ng-toolkit/universal';

@Injectable({
  providedIn: 'root'
})
export class ParallaxService {

  scrollPos:number = 0;

  constructor(private _sanitizer: DomSanitizer,
      @Inject(WINDOW) private window:Window) {
      window.addEventListener('scroll', (event) => {
          this.scrollPos = window.scrollY;
      });

      setTimeout(() => { window.scrollTo(0,0)}, 100);

  }

  //add [style.transform]="parallax.getTransform('xyz', 1)" id="xyz" to any DIV
  // you need to set an ID to the div, and pass the ID as Param1
  //PARAM1: ID of the div :string
  //PARAM2: Speed, 1 = normal, <1 slower, >1 faster
  //PARAM1: Reverse:boolean, optional as true
  getTransformY(id, speed:number, reverse?:boolean) {
        let direction:number = -1;
        if(reverse !== undefined && reverse === true) { direction = 1; }

        let totalCenterOffset = this.window.document.getElementById(id).offsetTop + (this.window.document.getElementById(id).offsetHeight/2);
        let scroll = this.scrollPos + (Math.max(this.window.document.documentElement.clientHeight, this.window.innerHeight || 0)/2);

        let transform3d = (((scroll-totalCenterOffset)/10))*direction;
        return this._sanitizer.bypassSecurityTrustStyle('translateY(' + transform3d*speed + 'px)');
  }

  getTransform3D(id, speed:number, reverse?:boolean) {
        let direction:number = -1;
        if(reverse !== undefined && reverse === true) { direction = 1; }

        let totalCenterOffset = this.window.document.getElementById(id).offsetTop + (this.window.document.getElementById(id).offsetHeight/2);
        let scroll = this.scrollPos + (Math.max(this.window.document.documentElement.clientHeight, this.window.innerHeight || 0)/2);

        let transform3d = (((scroll-totalCenterOffset)/10))*direction;
        return this._sanitizer.bypassSecurityTrustStyle('translateY(' + transform3d + 'px) perspective(300px) rotateY(' + (transform3d/5)*-1 + 'deg)');
  }

}
