import { DataService } from "../../services/data.service";
import { MetaService } from "../../services/meta.service";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { MenubarService } from "../../components/menubar/menubar.service";

@Component({
  selector: "app-imprintpage",
  templateUrl: "./imprintpage.component.html",
  styleUrls: ["./imprintpage.component.css"],
})
export class ImprintPageComponent implements OnInit {
  constructor(public data: DataService) {}

  ngOnInit() {}
}
