import { DataService } from "./data.service";
import { Injectable } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";

@Injectable({
  providedIn: "root"
})
export class MetaService {
  constructor(
    private meta: Meta,
    private title: Title,
    private data: DataService
  ) {}

  setMeta(pageTitle: string, description?: string) {
    let city = this.data.city.charAt(0).toUpperCase() + this.data.city.slice(1);
    pageTitle = pageTitle.charAt(0).toUpperCase() + pageTitle.slice(1);

    this.title.setTitle(
      `${
        pageTitle.length > 0 ? pageTitle + " | " : ""
      }Eventwerkstadt | Partyraum, Partykeller mieten in ${city}`
    );
    this.meta.updateTag({
      name: "description",
      content:
        description !== undefined
          ? description
          : `Wir bieten zwei Eventlocations zum Mieten im Herzen von ${city}. Ein Weinkeller bietet gehobene Atmosphäre, der Partykeller Platz zum lauten Feiern.`
    });
    this.meta.updateTag({ name: "author", content: `Eventwerkstadt` });
    this.meta.updateTag({
      name: "keywords",
      content: `Party,Raum,mieten,Feier,Fest,Geburtstag,Location,${city}`
    });
    this.meta.updateTag({ name: "language", content: `DE` });
    this.meta.updateTag({ name: "robots", content: `index, follow` });
  }
}
