import { DataService } from "./../../services/data.service";
import { Component, OnInit } from "@angular/core";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { FacebookTrackingService } from "../../app/facebook-tracking.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"]
})
export class FooterComponent implements OnInit {

  constructor(
      public data: DataService,
      private gtag: GoogleTagManagerService,
      private fb: FacebookTrackingService
  ) {}

  public async sendTagEvent(eventName: string) {
    this.gtag.pushTag({ event: eventName })
  }

  ngOnInit() {}

  public async trackLead() {
    this.fb.trackLead("footer");
  }
}
